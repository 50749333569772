import redirectConfig from "./ssiConfigs/redirect"
import pathsConfig from "./ssiConfigs/pathsConfig"
import RedirectionSignal from "./RedirectionSignal"
import resolveFullURLAndAppendHash from "./utils/resolveFullURLAndAppendHash"
import { URL } from "./utils/url"
import getOptions from "./utils/getOptions"

class ParamsInvalid extends RedirectionSignal {
  constructURL () {
    return redirectConfig.paramsInvalid
  }
}

class UserInvalid extends RedirectionSignal {
  constructURL (hash) {
    return resolveFullURLAndAppendHash(
      location.href,
      redirectConfig.userInvalid,
      hash
    )
  }
}

class StudentInvalid extends RedirectionSignal {
  constructURL (hash) {
    return resolveFullURLAndAppendHash(
      location.href,
      redirectConfig.studentInvalid,
      hash
    )
  }
}

class InternalError extends RedirectionSignal {
  constructURL (hash) {
    return resolveFullURLAndAppendHash(
      location.href,
      redirectConfig.internalError,
      hash
    )
  }
}

class StudentInactive extends RedirectionSignal {
  constructURL (hash) {
    return resolveFullURLAndAppendHash(
      location.href,
      redirectConfig.studentInactive,
      hash
    )
  }
}

class Enrollment extends RedirectionSignal {
  constructURL () {
    return pathsConfig.enrollment
  }
}

class Enrolled extends RedirectionSignal {
  constructURL (hash, jwts) {
    const customOptions = getOptions(jwts)
    // Priority: Global redirect -> Local redirect -> campus mypage
    if (customOptions.destination) {
      return customOptions.destination
    } else if (customOptions.redirect_uri) {
      let customURL = new URL(customOptions.redirect_uri, window.location.href)
      if (window.location.host === customURL.host) {
        return customURL.pathname
      }
    }
    return pathsConfig.myPage
  }
}

export default {
  ParamsInvalid,
  UserInvalid,
  StudentInvalid,
  StudentInactive,
  Enrollment,
  Enrolled,
  InternalError
}
