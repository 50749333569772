import getEnvConfig from "@ef/get-env-config"
import deepmerge from "deepmerge"

export default deepmerge({
  paramsInvalid: "paramsInvalid",
  userInvalid: "userInvalid",
  studentInvalid: "studentInvalid",
  studentInactive: "studentInactive",
  internalError: "internalError",
  help: "destinationHelpUrl?EFID=${EFID}&LNG=${LNG}",
  upsell: "destinationUpsellUrl?EFID=${EFID}&LNG=${LNG}",
  refer: "destinationReferUrl?EFID=${EFID}"
}, getEnvConfig("redirect"))
