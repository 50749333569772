import platformAuthConfig from "./ssiConfigs/platformAuthConfig"
import PlatformAuth from "@ef/platform-fe-auth"
import deepmerge from "deepmerge"

export const RESULT_ACCESS_HASH_INVALID = 0
export const RESULT_ACCESS_USER_INVALID = 1
export const RESULT_ACCESS_STUDENT_INVALID = 2
export const RESULT_ACCESS_STUDENT_VALID = 3

export default () => {
  return new Promise((resolve) => {
    let platformAuth = new PlatformAuth(platformAuthConfig)
    let tokens = {}
    let jwts = {}
    let assignTokens = (tkns, newJwts) => {
      tokens = deepmerge(tokens, tkns)
      jwts = newJwts
    }
    let eventsHooked = []
    let hookEvents = (event, callback) => {
      eventsHooked.push([event, callback])
      platformAuth.on(event, callback)
    }
    let unhookEventsAndResolve = (value) => {
      for (let evt of eventsHooked) {
        platformAuth.off(evt[0], evt[1])
      }
      resolve(value)
    }

    hookEvents("efid", assignTokens)
    hookEvents("tokens", assignTokens)

    let makeResolutionDecision = (eventName) => {
      return () => {
        if (!tokens || !tokens.id) {
          if (eventName === "redirect") {
            return unhookEventsAndResolve({
              result: RESULT_ACCESS_HASH_INVALID
            })
          }

          return unhookEventsAndResolve({
            result: RESULT_ACCESS_USER_INVALID
          })
        }

        if (!tokens.account) {
          return unhookEventsAndResolve({
            result: RESULT_ACCESS_STUDENT_INVALID
          })
        }

        unhookEventsAndResolve({
          result: RESULT_ACCESS_STUDENT_VALID,
          tokens: tokens,
          jwts: jwts
        })
      }
    }

    hookEvents("tokens", makeResolutionDecision("tokens"))
    hookEvents("error", makeResolutionDecision("error"))
    hookEvents("redirect", makeResolutionDecision("redirect"))

    platformAuth.start()
  })
}

