import redirections from "./redirections"
import RedirectionSignal from "./RedirectionSignal"
import endpoints from "./ssiConfigs/endpoints"
import platformAuthResolver, {
  RESULT_ACCESS_HASH_INVALID,
  RESULT_ACCESS_USER_INVALID,
  RESULT_ACCESS_STUDENT_INVALID
} from "./platformAuthResolver"

export default () => {
  let tokenCache
  let jwtCache
  let hashCache = location.hash
  return Promise.resolve()
    .then(() => {
      return platformAuthResolver()
    })
    .then(({
      result, tokens, jwts
    }) => {
      if (result === RESULT_ACCESS_HASH_INVALID) {
        throw new redirections.ParamsInvalid()
      }
      if (result === RESULT_ACCESS_USER_INVALID) {
        throw new redirections.UserInvalid()
      }
      if (result === RESULT_ACCESS_STUDENT_INVALID) {
        throw new redirections.StudentInvalid()
      }
      tokenCache = tokens
      jwtCache = jwts
    })
    .then(() => {
      return fetch(`${endpoints.studentStatus}/status`, {
        headers: {
          "Authorization": `Bearer ${tokenCache.access}`,
          "X-EF-Access": tokenCache.account
        }
      })
    })
    .then((res) => {
      if (res.status === 401 || res.status === 403) {
        throw new redirections.UserInvalid()
      }

      if (res.status !== 200) {
        throw new Error(
          `Unexpected error, server returned with status code: ${res.status}`
        )
      }

      return res.json()
    })
    .then((studentStatus) => {
      if (studentStatus.selfStudyGrant !== true) {
        throw new redirections.StudentInactive()
      }

      if (studentStatus.enrolled !== true) {
        throw new redirections.Enrollment()
      }

      throw new redirections.Enrolled()
    })
    .then(() => {
      throw "Unexpected code path reached"
    }, (err) => {
      if (err instanceof RedirectionSignal) {
        return err.redirect(hashCache, jwtCache)
      } else {
        new redirections.InternalError().redirect(hashCache)
      }
    })
}
